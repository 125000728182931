// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";
import ReactPlayer from "react-player";

import Icon from "./icon.png";

const Iconostream = () => {
  return (
    <div className="Iconostream embed-responsive embed-responsive-16by9">
      <ReactPlayer
        className="embed-responsive-item border-0"
        controls
        playing
        width="100%"
        height="100%"
        light={Icon}
        url="http://96.237.115.25:8080/hls/iconostream.m3u8"
        config={{
          file: {
            forceVideo: true,
            forceHLS: true,
          },
        }}
      />
    </div>
  );
};

export default Iconostream;
