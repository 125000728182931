// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";

import Banner from "./banner.png";

import { RandomSubtitle } from "./Utils";

const Header = () => {
  return (
    <header
      className="Header"
      style={{ paddingTop: "1vh", paddingBottom: "1vh" }}
    >
      <img src={Banner} className="Banner img-fluid" alt="Banner" />
      {RandomSubtitle()}
    </header>
  );
};

export default Header;
