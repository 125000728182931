// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import Stream from "./Stream";

const App = () => {
  return (
    <div className="App container-fluid justify-content-center align-items-center align-content-center text-center">
      {Header()}
      <Stream />
      {Footer()}
    </div>
  );
};

export default App;
