// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const rules = [
  "Don’t outwardly make it an intention to be aggressive or mean or hurtful.",
  "Don’t cause problems with other people on the site. This includes excessive trolling.",
  "Trolling in some ways is perfectly fine to a limit, but degradation of someone's character continuously is not.",
  "When posting NSFW material, put a quotation mark before the link. The picture cannot show.",
  "Don’t post illegal content (including but not limited to child pornography and gore).",
  "No spamming. If you’re posting content for someone to see (whether streamer or chat) post in moderation.",
  "Don’t purposely try to get people to leave the site, whether an anon or a regular.",
  "Don’t advertise other streaming sites.",
  "Complaints about the stream should be constructive.",
];
const ruleList = rules.map((rule, index) => <li key="rule-{index}">{rule}</li>);

const reactions = [
  "Warning",
  "2 Hour Ban",
  "1 Day Ban",
  "1 Week Ban",
  "1 Month Ban",
  "Permanent Ban",
];
const reactionList = reactions.map((reaction, index) => (
  <tr key="reaction-{index}">
    <td>{index + 1}</td>
    <td>{reaction}</td>
  </tr>
));

const Rules = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "black",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Iconoworks Rules
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "gray",
          color: "white",
        }}
      >
        <ol className="Rules-List">{ruleList}</ol>
        <h5>Reaction Scale:</h5>
        <Table striped bordered hover variant="dark">
          <thead>
            <td>Number of offenses</td>
            <td>Reaction</td>
          </thead>
          {reactionList}
        </Table>
        <h5>Special Cases:</h5>
        <Table striped bordered hover variant="dark">
          <tr>
            <td>
              Causing significant problems with:
              <ul>
                <li>a team member, or</li>
                <li>multiple members</li>
              </ul>
            </td>
            <td>1 Week Ban</td>
          </tr>
          <tr>
            <td>Posting illegal content</td>
            <td>Permanent Ban</td>
          </tr>
          <tr>
            <td>Ban evasion</td>
            <td>1 Week Ban per attempt</td>
          </tr>
        </Table>
        Admins have final word on reactions. You can appeal, complain, and
        report to one of our moderators or admins. If you do have a complaint,
        PLEASE screenshot what it is you are complaining about, otherwise
        something may not be done due to a lack of certainty. If no screenshot
        or other evidence can be provided, the complaint will be expunged.
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "gray",
          color: "white",
        }}
      >
        <Button variant="dark" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Rules;
