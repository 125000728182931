// Copyright 2020. Iconoworks. All Rights Reserved.
import React, { Component } from "react";
import { TwitchChat } from "react-twitch-embed";

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      width: 0,
    };
  }

  setFrameSize = () => {
    const width = this.divElement.clientWidth;

    // The row is 8 and 4, and the aspect ratio of the stream is 16:9. Thus,
    // for a 4 width column, the optimal size for height is below.
    const height = Math.round((width * 4.5) / 4);

    this.setState({ height, width });
  };

  componentDidMount() {
    this.setFrameSize();
    window.addEventListener("resize", this.setFrameSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setFrameSize);
  }

  render() {
    const { width, height } = this.state;

    // XXX(zjod95) Need the Restream.io iframe so that it is active, but we
    // don't want to show this chat, so display = none.
    return (
      <div
        className="Chat"
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        <iframe
          title="donotshow"
          src="https://chat.restream.io/embed?token=03d964ac-f95c-4a73-bad3-737cdd7c4400"
          style={{
            display: "none",
          }}
        />
        <TwitchChat
          channel="iconoworksstream"
          theme="dark"
          height={height}
          width={width}
        />
      </div>
    );
  }
}

export default Chat;
