// Copyright 2020. Iconoworks. All Rights Reserved.
import React, { Component } from "react";
import { Button, Dropdown, SplitButton } from "react-bootstrap";

import Chat from "./Chat";
import Iconostream from "./Iconostream";
import Rules from "./Rules";
import Twitch from "./Twitch";

const streamDict = {
  Twitch: Twitch(),
  Misc: Iconostream(),
};

class Stream extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stream: "Twitch",
      show_rules: false,
    };
  }

  chooseStream = (stream, evt) => {
    evt.preventDefault();
    this.setState({ stream });
  };

  setShowRules = (show_rules) => {
    this.setState({ show_rules });
  };

  render() {
    const { stream, show_rules } = this.state;

    return (
      <div className="Stream">
        <div className="Stream-Column column">
          <div
            className="Stream-Row row justify-content-center"
            style={{
              paddingLeft: "1vw",
              paddingRight: "1vw",
              paddingBottom: "1vh",
            }}
          >
            <div className="Stream-Viewer col-8">{streamDict[stream]}</div>
            <div className="Stream-Chat col-4">
              <Chat />
            </div>
          </div>
        </div>

        <div
          className="Stream-FooterRow row justify-content-center"
          style={{
            paddingBottom: "1vh",
            paddingTop: "1vh",
          }}
        >
          <div className="Stream-Selector col-8">
            <SplitButton
              drop="up"
              key={stream.concat(" Stream")}
              id="dropdown-split-stream"
              variant="primary"
              title={stream.concat(" Stream")}
            >
              <Dropdown.Item eventKey="Twitch" onSelect={this.chooseStream}>
                Twitch Stream
              </Dropdown.Item>
              <Dropdown.Item eventKey="Misc" onSelect={this.chooseStream}>
                Misc Stream
              </Dropdown.Item>
            </SplitButton>
          </div>

          <div className="Stream-Rules col-4">
            <Button variant="dark" eventkey="true" onClick={this.setShowRules}>
              Iconoworks Rules
            </Button>

            <Rules
              show={show_rules}
              eventkey="false"
              onHide={this.setShowRules}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Stream;
