// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";

function RandomSubtitle() {
  var subtitleList = [
    "Voted 'Most Likely to Pick Oddjob.'",
    "Screenwatching since 1995.",
    "Daddy taught us not to be ashamed of our streams.",
    "Mom said it's my turn to stream!",
    "This is our only normal controller.  Here, use the Madcatz.",
    "Stop liking what we don't like!",
    "It ain't gonna watch itself!",
    "Where the fun is made up, and the streams dont matter!",
    "You found us by mistake.",
    "Your parents would be ashamed of you.",
    "Coming soon to an unimportant event near you!",
    "Waifus are just a joke, right?",
    "Streams not included.",
    "You're our 10th visitor!  Click here to claim your prize!",
    "We won't tell if you won't.",
    "You know that thing you love?  Let's ruin it.",
    "Hipsters angry at hipsters being more hipster than regular hipsters.",
    "We've seen enough hentai to know where this is going.",
    "The best talents in streaming...weren't available, so here we are!",
    "The *other* other guys.",
    "Please use other door.",
    "I don't know how to properly manage Entertainment Tech.",
    "It's not like you have anything better to do.",
    "Remember, you're here forever.",
    "I-Its' not like we want you to w-watch or anything...baka...",
    "Fuck I a m so butt mad.",
    "The dream is always dead.",
    "I'm so good at this game!",
    "Established 1953.",
    "R.I.P. Obarma ;_;7",
    "Doosh wears his sunglasses at night.",
    "Assuming direct control.",
    "It is dark.  You are likely to be eaten by a grue.",
    "Woah dat ass too fat, woop woop! ~ Darkauryn",
    "This site is peaceful.  Its inhabitants, kind.",
    "You can check out any time you like, but you can never leave.",
    "MEGA MAN'S IN SMASH!",
    "WHAT I LEARNED IN STREAMING SCHOOL IS",
    "Do a flip!",
    "Back of the knee, maybe?",
    "Xiao Pai is the best girl.",
    "The good, the bad, and the Iconoworks.",
    "We know what you did last summer.",
    "We all know that feel.",
    "What's this got to do with streams?",
    "Stealing your stars since 1999.",
    "Please understand.",
    "[continued laughter]",
    "You got boost power!",
    "We may not be the best, but we always have the blue shell.",
    "gitgud",
    "Maybe you shouldn't have pirated it :^)",
    "FIGHT, ICONOWORKS!  FOR EVERLASTING PEACE!",
    "WHAT A HORRIBLE NIGHT TO BE ON ICONOWORKS.",
  ];
  var randomNumber = Math.floor(Math.random() * subtitleList.length);
  return (
    <p className="Subtitle text-center text-danger">
      {subtitleList[randomNumber]}
    </p>
  );
}

export { RandomSubtitle };
