// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";
import { TwitchPlayer } from "react-twitch-embed";

const Twitch = () => {
  return (
    <div className="Twitch embed-responsive embed-responsive-16by9">
      <TwitchPlayer
        className="embed-responsive-item"
        channel="iconoworksstream"
        id="iconoworksstream"
        theme="dark"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default Twitch;
