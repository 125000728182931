// Copyright 2020. Iconoworks. All Rights Reserved.
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faSteamSquare } from "@fortawesome/free-brands-svg-icons";

import Danjin from "./danjin.png";

const Footer = () => {
  const socialStyle = {
    paddingLeft: "10px",
    paddingRight: "10px",
  };

  return (
    <footer
      className="Footer"
      style={{ paddingTop: "1vh", paddingBottom: "2vh" }}
    >
      <div className="Footer-Social row justify-content-center">
        <a
          href="http://steamcommunity.com/groups/iconoworks"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faSteamSquare}
            size="4x"
            color="#171a21"
            style={socialStyle}
          />
        </a>
        <a
          href="https://discord.gg/385UsEV"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faDiscord}
            size="4x"
            color="#7289da"
            style={socialStyle}
          />
        </a>
      </div>
      <div className="Footer-Image">
        <img
          src={Danjin}
          className="App-FooterImage img-fluid"
          alt="FooterImage"
        />
      </div>
    </footer>
  );
};

export default Footer;
